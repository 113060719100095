import * as React from "react"
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const navigation = [
  { name: 'Codepen', href: 'https://codepen.io/cartuhok' },
  { name: 'Github', href: 'https://github.com/cartuhok' },
  { name: 'LinkedIn', href: 'https://www.linkedin.com/in/carterr/' }
]

export default function SimpleHero(props) {
  return (
    <div className="relative bg-white overflow-hidden">


      <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-2">
        <Popover>
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="https://www.carterrink.com/">
                  <span className="sr-only">Workflow</span>

                  <svg className="h-8 w-auto"
                         viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="">
                        <path fill={props.color} d="M1.14266 11.4855C1.42681 11.0119 2.04107 10.8584 2.51465 11.1425L12.0002 16.8338L21.4857 11.1425C21.9592 10.8584 22.5735 11.0119 22.8576 11.4855C23.1418 11.9591 22.9882 12.5733 22.5146 12.8575L12.5146 18.8575C12.198 19.0475 11.8023 19.0475 11.4857 18.8575L1.48566 12.8575C1.01208 12.5733 0.85851 11.9591 1.14266 11.4855Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        <path fill={props.color} d="M1.14266 15.4855C1.42681 15.0119 2.04107 14.8584 2.51465 15.1425L12.0002 20.8338L21.4857 15.1425C21.9592 14.8584 22.5735 15.0119 22.8576 15.4855C23.1418 15.9591 22.9882 16.5733 22.5146 16.8575L12.5146 22.8575C12.198 23.0475 11.8023 23.0475 11.4857 22.8575L1.48566 16.8575C1.01208 16.5733 0.85851 15.9591 1.14266 15.4855Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        <path fill={props.color} d="M22 8L12 14L2 8L12 2L22 8Z" undefined="1"></path>
                        <path fill={props.color} d="M11.4855 1.14251C11.8022 0.952498 12.1978 0.952498 12.5145 1.14251L22.5145 7.14251C22.8157 7.32323 23 7.64874 23 8C23 8.35126 22.8157 8.67677 22.5145 8.85749L12.5145 14.8575C12.1978 15.0475 11.8022 15.0475 11.4855 14.8575L1.4855 8.85749C1.1843 8.67677 1 8.35126 1 8C1 7.64874 1.1843 7.32323 1.4855 7.14251L11.4855 1.14251ZM3.94365 8L12 12.8338L20.0563 8L12 3.16619L3.94365 8Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        </svg>

                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className={`bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:${props.focusColor}`}>
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:space-x-10">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <div className="hidden md:block text-right">
              <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                <a
                  href="mailto:carter.m.rink@gmail.com"
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md ${props.textColor} bg-white hover:bg-gray-50`}
                >
                  Contact
                </a>
              </span>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>

                  <svg className="h-8 w-auto"
                         viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="">
                        <path fill={props.color} d="M1.14266 11.4855C1.42681 11.0119 2.04107 10.8584 2.51465 11.1425L12.0002 16.8338L21.4857 11.1425C21.9592 10.8584 22.5735 11.0119 22.8576 11.4855C23.1418 11.9591 22.9882 12.5733 22.5146 12.8575L12.5146 18.8575C12.198 19.0475 11.8023 19.0475 11.4857 18.8575L1.48566 12.8575C1.01208 12.5733 0.85851 11.9591 1.14266 11.4855Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        <path fill={props.color} d="M1.14266 15.4855C1.42681 15.0119 2.04107 14.8584 2.51465 15.1425L12.0002 20.8338L21.4857 15.1425C21.9592 14.8584 22.5735 15.0119 22.8576 15.4855C23.1418 15.9591 22.9882 16.5733 22.5146 16.8575L12.5146 22.8575C12.198 23.0475 11.8023 23.0475 11.4857 22.8575L1.48566 16.8575C1.01208 16.5733 0.85851 15.9591 1.14266 15.4855Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        <path fill={props.color} d="M22 8L12 14L2 8L12 2L22 8Z" undefined="1"></path>
                        <path fill={props.color} d="M11.4855 1.14251C11.8022 0.952498 12.1978 0.952498 12.5145 1.14251L22.5145 7.14251C22.8157 7.32323 23 7.64874 23 8C23 8.35126 22.8157 8.67677 22.5145 8.85749L12.5145 14.8575C12.1978 15.0475 11.8022 15.0475 11.4855 14.8575L1.4855 8.85749C1.1843 8.67677 1 8.35126 1 8C1 7.64874 1.1843 7.32323 1.4855 7.14251L11.4855 1.14251ZM3.94365 8L12 12.8338L20.0563 8L12 3.16619L3.94365 8Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        </svg>

                  </div>
                  <div className="-mr-2">
                    <Popover.Button className={`bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:${props.focusColor}`}>
                      <span className="sr-only">Close main menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1>
                <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                  Hey I'm
                </span>
                <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                  <span className="block text-gray-900">Carter Rink</span>
                  <span className={`block ${props.textColor}`}>Front End Developer</span>
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                (And coffee lover.) I'm currently a full time developer at Homesite Insurance helping with their digital marketing flows using things like SalesForce Marketing Cloud. But enough about me, let's talk about you. You need a well designed custom website, and I can help.
              </p>

            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                width={640}
                height={784}
                fill="none"
                viewBox="0 0 640 784"
                aria-hidden="true"
              >
                
              </svg>
              <div className="relative mx-auto w-full rounded-lg  lg:max-w-md">
          
                  <img
                    className="w-full"
                    src="https://opendoodles.s3-us-west-1.amazonaws.com/coffee.gif"
                    alt=""
                  />           
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
