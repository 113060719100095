import * as React from "react"

  export default function Products(props) {
    return (
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="md:flex md:items-center md:justify-between">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">{props.name}</h2>

          </div>
  
          <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 lg:grid-cols-4 md:gap-y-4 lg:gap-x-4">
            {props.projects.map((project) => (
              <div key={project.id} className="group relative">
                <div className="w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden sm:aspect-w-16 sm:aspect-h-9">
                  <img
                    src={project.imageSrc}
                    alt={project.imageAlt}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">
                  <a href={project.href}>
                    <span className="absolute inset-0" />
                    {project.name}
                  </a>
                </h3>
                <p className="mt-1 text-sm text-gray-500">{project.color}</p>
                <p className="mt-1 text-sm font-medium text-gray-900">{project.price}</p>
              </div>
            ))}
          </div>
  
   
        </div>
      </div>
    )
  }
  