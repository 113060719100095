const websites = [
    {
      id: 1,
      name: 'WGBH FAQ Help Site',
      color: 'SalesForce CloudPage, Einstein Chatbot',
      price: '2018',
      href: '/FAQ-Site/',
      imageSrc: 'https://i.imgur.com/863BMml.png',
      imageAlt: 'WGBH Help Site',
    },
    {
      id: 2,
      name: 'WGBH Digital Holiday Card',
      color: 'CreateJS, CSS Grid',
      price: '2020',
      href: '/holiday-card/',
      imageSrc: 'https://i.imgur.com/is79s2Q.png',
      imageAlt: 'WGBH Digital Holiday Card',
    },
    {
      id: 3,
      name: 'Online Learning Center',
      color: 'Gatsby JS, Tailwind CSS',
      price: '2021',
      href: '/classroom/',
      imageSrc: 'https://i.imgur.com/LcVSM8M.png',
      imageAlt: 'Online Learning Center',
    },
    {
      id: 4,
      name: 'Marketing Suite Company',
      color: 'Gatsby JS, Tailwind CSS',
      price: '2021',
      href: '/marketing/',
      imageSrc: 'https://i.imgur.com/YCVjo61.png',
      imageAlt: 'Marketing Suite Company',
    }
  ]

export default websites