import * as React from "react"
/* This example requires Tailwind CSS v2.0+ */
export default function CenteredHeader(props) {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 sm:pb-0 lg:pb-0 md:pb-0">
          <div className="text-center">
            <h2 className={`text-base font-semibold ${props.textBold} tracking-wide uppercase`}>Still not convinced?</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Check out my work.
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              Take a look at some of my work below. I'm really good at making websites for small business. 
            </p>
          </div>
        </div>
      </div>
    )
  }
  