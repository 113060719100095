import * as React from "react"
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'No Hosting Fees',
    description: 'Paying $14/m+ just to keep your site online? It’s easy to think that monthly fees are part of the deal.  Unless your website is in the top 1% of traffic generating sites in the world, monthly fees should be far from a given.',
  },
  {
    name: 'Brand Identity',
    description: 'A website is only a small portion of creating your digital identity. I can help with logo design, SEO, and digital marketing like email campaigns.',
  },
  {
    name: 'Highly Customizable',
    description: "Tried your hand at a web builder and didn't quite come out the way you wanted? I'll work with you to design the website that best fits your business needs and style.",
  },
  {
    name: 'Modern Design',
    description: "Paying less doesn’t mean you can’t have the best.  I follow modern design principles to ensure that your site is responsive and looks and performs great no matter what device it's viewed on.",
  }
]

export default function GridInfo() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Don't overpay for your small business website.</h2>
          <p className="mt-4 text-lg text-gray-500">
            Paying a monthly fee just to keep your site online? I'll let you in on a little secret: most businesses don't need to. Interested? Keep reading.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
