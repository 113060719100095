const emails = [
    {
      id: 1,
      name: 'Travel Company Listings',
      href: 'https://codepen.io/cartuhok/pen/QWgdjyj',
      imageSrc: 'https://i.imgur.com/LLIVpse.png',
      imageAlt: 'Travel Company Listings',
    },
    {
      id: 2,
      name: 'Landscaping Email',
      href: 'https://codepen.io/cartuhok/pen/vYNBPZd',
      imageSrc: 'https://i.imgur.com/yVs8xHA.png',
      imageAlt: 'Landscaping Email',
    },
    {
      id: 3,
      name: 'Healthy Meals Newsletter',
      href: 'https://codepen.io/cartuhok/pen/KKqadgM',
      imageSrc: 'https://i.imgur.com/gsWdJfw.png',
      imageAlt: 'Healthy Meals Newsletter',
    },
    {
      id: 4,
      name: 'Real Estate App Email',
      href: 'https://codepen.io/cartuhok/pen/YzQeQpM',
      imageSrc: 'https://i.imgur.com/M8EjSuz.png',
      imageAlt: 'Real Estate App Email',
    }
  ]

export default emails