import * as React from "react"
/* This example requires Tailwind CSS v2.0+ */
import { GlobeAltIcon, IdentificationIcon, InboxIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Certified AWS Cloud Practitioner',
    description:
      'I have professional experience working with AWS cloud and achieved my certification in 2020',
    icon: GlobeAltIcon,
  },
  {
    name: 'Over 5 years of professional experience',
    description:
      "I've been working in the field for over 5 years, and I'm always staying up-to-date with the latest design trends and technologies",
    icon: IdentificationIcon,
  },
  {
    name: 'Certified Marketing Cloud Email Specialist',
    description:
      'I achieved my certification in 2021, and have tons of other Salesforce Marketing Cloud experience from automation flows to journeys',
    icon: InboxIcon,
  },
]




export default function SimpleThreeCol(props) {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to send money.</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className={`flex items-center justify-center h-12 w-12 rounded-md ${props.bgMd} text-white`}>
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
