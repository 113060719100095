import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Blue } from "../data/color"
import websites from "../data/websites"
import emails from "../data/emails"
import LightCTA from "../components/ctas/lightCTA"
import FourColFooter from "../components/footers/fourColFooter"
import SimpleHero from "../components/heros/simpleHero"
import Products from "../components/features/products"
import GridInfo from "../components/features/gridInfo"
import CenteredHeader from "../components/headers/centeredHeader"
import SimpleThreeCol from "../components/features/simpleThreeCol"
import CenterContact from "../components/contact/centerContact"
import SocialOnlyFooter from "../components/footers/socialOnlyFooter"

const IndexPage = () => (

  /*
  <div>
    <SlantHero color={Color.pallet} />
    <SideBySideImgFeature color={Color.pallet} />
    <LightCTA color={Color.pallet} />
    <ThreeColFAQ />
    <FourColFooter color={Color.pallet} />
  </div>
  */
 
  <div>
    <SimpleHero color={Blue.color} textColor={Blue.textBold} focusColor={Blue.focusColor} />

    <GridInfo />

    <LightCTA color={Blue.color} bgLite={Blue.bgLite} bgBold={Blue.bgBold} textBold={Blue.textBold} hoverBold={Blue.hoverBold} />

    <CenteredHeader textBold={Blue.textBold}/>

    <Products name={"Website Portfolio"} projects={websites} textBold={Blue.textBold} textMd={Blue.textMd} id={'Products'}/>
    <Products name={"I do emails too!"} projects={emails} textBold={Blue.textBold} textMd={Blue.textMd} />

    <LightCTA color={Blue.color} bgLite={Blue.bgLite} bgBold={Blue.bgBold} textBold={Blue.textBold} hoverBold={Blue.hoverBold}/>


    <CenterContact focusColor={Blue.focusColor} borderColor={Blue.borderColor} bgBold={Blue.bgBold} bgLite={Blue.bgLite} />

    <SimpleThreeCol bgMd={Blue.bgMd} />

    <SocialOnlyFooter />


  </div>


  /*  
  <Layout>
    <Seo title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p>
  </Layout>
  */


)

export default IndexPage
